<template>
  <div class="typography-template">
    <div class="holder">
      <div class="types -green-bg">
        <h1>H1</h1>
        <h2>H2</h2>
        <h3>H3</h3>
        <h4>H4</h4>
        <p class="-body-3-heavy">Body 3 heavy</p>
        <p class="-btn-text">KNAPPTEXT</p>
        <p class="-caption">Caption</p>
        <p class="-body-1">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
        <p class="-body-2">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
        <p class="-body-3">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
      </div>
      <div class="types">
        <h1>H1</h1>
        <h2>H2</h2>
        <h3>H3</h3>
        <h4>H4</h4>
        <p class="-body-3-heavy">Body 3 heavy</p>
        <p class="-btn-text">KNAPPTEXT</p>
        <p class="-caption">Caption</p>
        <p class="-body-1">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
        <p class="-body-2">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
        <p class="-body-3">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
        <a href="#">Länk</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.holder {
  margin: 0 auto;
  max-width: $max-width;
  width: 100%;
}

.types {
  padding: $gutter;
  margin: $spacing-gutter-lg 0;

  > * {
    margin-bottom: $spacing-gutter-sm;
  }
}
</style>